<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Client Portal <span>Header Setting</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()">
                    <i class="fas fa-long-arrow-alt-right"></i>
                </button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="playbook-header-setting-form" ref="client-portal-setting-form">
                <div class="modal_body pt-4">
                    <div class="setting_wpr">
                        <!-- <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Company Name</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                    <Field autocomplete="off" type="text" name="company_branding" v-model="form.company_branding" placeholder="ex: Superfit Coaching" />
                                </div>
                                <ErrorMessage name="company_branding" class="text-danger" />
                            </div>
                        </div> -->
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Header Background Color <a class="reset-default ml-auto" @click="handleResetToDefault('header_bgcolor')">Reset to default</a></label>
                                <Field autocomplete="off" name="header_bgcolor" v-model="form.header_bgcolor" type="text" label="element color">
                                    <color-picker v-model="form.header_bgcolor" :classes="{ 'has-error': errors.header_bgcolor }" />
                                </Field>
                                <ErrorMessage name="header_bgcolor" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Header Text Color <a class="reset-default ml-auto" @click="handleResetToDefault('header_textcolor')">Reset to default</a></label>
                                <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="element color">
                                    <color-picker v-model="form.header_textcolor" :classes="{ 'has-error': errors.header_textcolor }" />
                                </Field>
                                <ErrorMessage name="header_textcolor" class="text-danger" />
                            </div>
                        </div>
                        <h3 class="sub_header">Header Logo</h3>
                        <image-library v-model="form.logo" image-type="public-logo" upload-text="Logo" />
                        <h3 class="sub_header mt-5">Owner Image</h3>
                        <image-library v-model="form.author" image-type="avatar" upload-text="Logo" />

                        <h3 class="sub_header mt-5">Navigation Bar</h3>
                        <div class="navbar mt-3">
                            <div class="category_navbar p-0">
                                <h4 @click="menuEditedId = homeMenu.id;" v-if="menuEditedId != homeMenu.id">{{ homeMenu.name }}</h4>
                                <div class="rename_fld m-0" v-else>
                                    <div class="tab-edit-container px-2">
                                        <Field autocomplete="nofill" type="text" name="home_tab_name" v-model="homeMenu.name" :placeholder="`${homeMenu.type.replaceAll('-', '')} tab name`" rules="required" :label="`${homeMenu.type.replaceAll('-', '')} tab name`" />
                                        <ErrorMessage name="home_tab_name" class="text-danger" />
                                    </div>
                                    <button type="button" class="save_btn" @click="handleMenuSave(homeMenu)" v-if="menuEditedId == homeMenu.id" :disabled="menuRenameLoaderId == homeMenu.id">{{ menuRenameLoaderId == homeMenu.id ? 'Saving' : 'Save' }}</button>
                                </div>
                                <div class="reset_tab px-2" v-tooltip="'Reset To Default'" v-if="homeMenu.name.toString().toLowerCase() !== homeMenu.type">
                                    <button type="button" @click="handleResetMenuName(homeMenu, 'Home')"><i class="fas fa-undo"></i></button>
                                </div>
                            </div>
                        </div>
                        <draggable v-model="navigationMenus" item-key="id" :animation="200" handle=".handle-cat-drag">
                            <template #item="{ element }">
                                <div class="navbar mt-4">
                                    <div class="category_navbar">
                                        <i class="fas fa-arrows-alt handle-cat-drag"></i>
                                        <h4 @click="menuEditedId = element.id;" v-if="menuEditedId != element.id">{{ element.name }}</h4>
                                        <div class="rename_fld m-0" v-else>
                                            <div class="tab-edit-container px-2">
                                                <Field autocomplete="nofill" type="text" name="home_tab_name" v-model="element.name" :placeholder="`${element.type.replaceAll('-', '')} tab name`" rules="required" :label="`${element.type.replaceAll('-', '')} tab name`" />
                                                <ErrorMessage name="home_tab_name" class="text-danger" />
                                            </div>
                                            <button type="button" class="save_btn" @click="handleMenuSave(element)" v-if="menuEditedId == element.id" :disabled="menuRenameLoaderId == element.id">{{ menuRenameLoaderId == element.id ? 'Saving' : 'Save' }}</button>
                                        </div>
                                        <div class="reset_tab px-2" v-tooltip="'Reset To Default'" v-if="element.name.toString().toLowerCase().replaceAll(' ', '-') !== element.type">
                                            <button type="button" @click="handleResetMenuName(element, element.type)"><i class="fas fa-undo"></i></button>
                                        </div>
                                        <label :for="`menu-checkbox-${element.id}`" class="switch_option capsule_btn p-0 ml-2">
                                            <input type="checkbox" :id="`menu-checkbox-${element.id}`" v-model="element.is_enabled" @change="handleMenuSave(element)" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <template v-if="element.child_menus.length">
                                        <draggable v-model="element.child_menus" item-key="id" tag="ul" class="navbar_setting" :animation="200" handle=".handle-sub-drag">
                                            <template #item="{ element: subMenu }">
                                                <li>
                                                    <i class="fas fa-arrows-alt handle-sub-drag"></i>
                                                    <h4 @click="menuEditedId = subMenu.id;" v-if="menuEditedId != subMenu.id">{{ subMenu.name }}</h4>
                                                    <div class="rename_fld m-0" v-else>
                                                        <div class="tab-edit-container px-2">
                                                            <Field autocomplete="nofill" type="text" name="home_tab_name" v-model="subMenu.name" :placeholder="`${subMenu.type.replaceAll('-', '')} tab name`" rules="required" :label="`${subMenu.type.replaceAll('-', '')} tab name`" />
                                                            <ErrorMessage name="home_tab_name" class="text-danger" />
                                                        </div>
                                                        <button type="button" class="save_btn" @click="handleMenuSave(subMenu)" v-if="menuEditedId == subMenu.id" :disabled="menuRenameLoaderId == subMenu.id">{{ menuRenameLoaderId == subMenu.id ? 'Saving' : 'Save' }}</button>
                                                    </div>
                                                    <div class="reset_tab px-2" v-tooltip="'Reset To Default'" v-if="subMenu.name.toString().toLowerCase().replaceAll(' ', '-') !== subMenu.type">
                                                        <button type="button" @click="handleResetMenuName(subMenu, subMenu.type)"><i class="fas fa-undo"></i></button>
                                                    </div>
                                                    <label :for="`menu-checkbox-${subMenu.id}`" class="switch_option capsule_btn p-0 ml-2">
                                                        <input type="checkbox" :id="`menu-checkbox-${subMenu.id}`" v-model="subMenu.is_enabled" @change="handleMenuSave(subMenu)" :true-value="1" :false-value="0" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </li>
                                            </template>
                                        </draggable>
                                    </template>
                                </div>
                            </template>
                        </draggable>

                        <div class="navbar mt-4">
                            <div class="calendar_setting border-0">
                                <h3 class="sub_heading2">Active tab color on Navigation bar</h3>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Navigation Active Color <a class="reset-default" @click="handleResetToDefault('portal_tab_bg_color')">Reset to default</a></label>
                                        <Field autocomplete="off" name="portal_tab_bg_color" v-model="form.portal_tab_bg_color" type="text" label="element color">
                                            <color-picker v-model="form.portal_tab_bg_color" :classes="{ 'has-error': errors.portal_tab_bg_color }" />
                                        </Field>
                                        <ErrorMessage name="portal_tab_bg_color" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="updateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="updateLoader"></i> {{ updateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import Draggable from 'vuedraggable'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    export default {
        name: 'Client Portal Header Setting',

        data () {
            return {
                form: {
                    company_branding: '',
                    header_bgcolor: '',
                    header_textcolor: '',
                    logo: '',
                    author: '',
                    portal_tab_bg_color: '#2F80ED',
                },
                homeMenu: {},
                navigationMenus: [],
                menuEditedId: 0,
                menuRenameLoaderId: 0,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ColorPicker,
            ImageLibrary,
            Draggable,
        },

        watch: {
            modelValue () {
                const vm = this;
                const navigationMenus = vm.clientPortal.navigation_menus ? JSON.parse(JSON.stringify(vm.clientPortal.navigation_menus)) : [];

                vm.menuEditedId = 0;

                if (navigationMenus.length) {
                    vm.homeMenu = navigationMenus.shift();
                    vm.navigationMenus  = navigationMenus;
                }

                vm.resetForm();
            },
        },

        computed: mapState({
            updateLoader: state => state.clientPortalModule.updateLoader,
            clientPortal: state => state.clientPortalModule.clientPortal,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
                saveNavigationMenu: 'clientPortalModule/saveNavigationMenu',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    company_branding: vm.clientPortal.company_branding ? vm.clientPortal.company_branding : '',
                    header_bgcolor: vm.clientPortal.header_bgcolor ? vm.clientPortal.header_bgcolor : '',
                    header_textcolor: vm.clientPortal.header_textcolor ? vm.clientPortal.header_textcolor : '',
                    logo: vm.clientPortal.logo ? vm.clientPortal.logo : '',
                    author: vm.clientPortal.author ? vm.clientPortal.author : '',
                    portal_tab_bg_color: vm.clientPortal.portal_tab_bg_color ? vm.clientPortal.portal_tab_bg_color : '#2F80ED',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.updatePortalSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },

            handleMenuSave (menu) {
                const vm        = this;
                const form      = vm.$refs['client-portal-setting-form'];
                const params    = {};

                params.setFieldError = form.setFieldError;

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.menuRenameLoaderId = menu.id

                        vm.saveNavigationMenu(menu).then(() => {
                            vm.menuEditedId = 0;
                            vm.menuRenameLoaderId = 0;
                        });
                    }
                });
            },

            handleResetMenuName (menu, defaultText) {
                const vm = this;
                const name = defaultText.replaceAll('-', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

                menu.name = name;

                vm.handleMenuSave(menu);
            },

            handleResetToDefault (key) {
                const vm = this;

                const defaults = {
                                    header_bgcolor: '#ffffff',
                                    header_textcolor: '#151111',
                                    portal_tab_bg_color: '#2C3E50',
                                };


                vm.form[key] = defaults[key];
            },
        },
    }
</script>

<style scoped>
    :deep(.playbook-header-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.playbook-header-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
    .navbar{
        background: #fff;
        border-radius: 6px;
    }
    .category_navbar{
        display: flex;
        align-items: center;
        padding-right: 20px;
    }
    .category_navbar .handle-cat-drag{
        font-size: 15px;
        color: #777777;
        cursor: move;
        margin-left: 15px;
    }
    .category_navbar h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        padding: 12px 15px;
        margin-right: auto;
    }
    .navbar .rename_fld{
        background: #f7faff;
        padding-right: 45px;
        position: relative;
        flex: 1 1 auto;
        margin: 0 10px;
    }
    .navbar .rename_fld input{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 12px 10px;
        background: transparent;
    }
    .navbar .category_navbar .rename_fld input{
        font-size: 16px;
        line-height: 22px;
        padding: 12px 10px;
    }
    .navbar .rename_fld button.save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .navbar .navbar_setting{
        padding: 15px 10px;
        border-top: 1px solid #e9e9e9;
    }
    .navbar .navbar_setting li{
        padding: 10px 5px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .navbar .navbar_setting li .handle-sub-drag{
        font-size: 15px;
        color: #777;
        cursor: move;
    }
    .navbar .navbar_setting li h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        padding: 0 15px;
        margin-right: auto;
    }
    .navbar .calendar_setting{
        padding: 25px 20px;
    }
    .reset_tab i{
        color: #5a5a5a;
        font-size: 12px;
    }
</style>
